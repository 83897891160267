var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0 m-background-gradient",
      staticStyle: { "max-width": "100%" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down" },
            [
              _c("v-img", {
                staticClass: "m-acc-illustration-img",
                attrs: {
                  contain: "",
                  src: require("@af/modules/auth/assets/Login_illustration.png"),
                  position: "top left",
                },
              }),
              _c("div", {
                staticClass:
                  "d-flex flex-column align-center justify-center h-100",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center h-100" },
            [
              _c("v-card", { staticClass: "m-login pa-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c("v-spacer", {
                      staticStyle: { "flex-grow": "0.6 !important" },
                    }),
                    _c(
                      "v-card-text",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-container",
                          {
                            class: {
                              "pa-0": _vm.$vuetify.breakpoint.smAndDown,
                            },
                          },
                          [
                            _c("v-row", { attrs: { "no-gutters": "" } }, [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-md-center mb-2 m-text-black mk-poppins--text",
                                },
                                [_vm._v(" Sign in to Fluency First ")]
                              ),
                            ]),
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                attrs: { "lazy-validation": "" },
                                model: {
                                  value: _vm.valid,
                                  callback: function ($$v) {
                                    _vm.valid = $$v
                                  },
                                  expression: "valid",
                                },
                              },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "pl-0", attrs: { fluid: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Email",
                                            autocomplete: "username",
                                            "validate-on-blur": "",
                                            "prepend-icon": "email",
                                            rules: [_vm.emailValidation],
                                            autofocus: "",
                                          },
                                          model: {
                                            value: _vm.form_data.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form_data,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression: "form_data.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          justify: "end",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "clickable primary--text mt-1 mb-0",
                                            staticStyle: {
                                              "font-size": "0.81rem",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push(
                                                  "/auth/forgot-password"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Forgot Password? ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "pt-0",
                                          attrs: {
                                            "prepend-icon": "lock",
                                            "append-icon": _vm.show_password
                                              ? "visibility_off"
                                              : "visibility",
                                            type: _vm.show_password
                                              ? "text"
                                              : "password",
                                            name: "password",
                                            autocomplete: "current-password",
                                            label: "Password",
                                          },
                                          on: {
                                            "click:append": function ($event) {
                                              _vm.show_password =
                                                !_vm.show_password
                                            },
                                          },
                                          nativeOn: {
                                            keypress: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              _vm.login(
                                                $event,
                                                "basic",
                                                (_vm.provider = "password")
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form_data.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form_data,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form_data.password",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      "no-gutters": "",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mk-login-page-login-btn",
                                        attrs: {
                                          loading: _vm.signin_pending.basic,
                                          elevation: "0",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.valid
                                              ? _vm.login(
                                                  $event,
                                                  "basic",
                                                  (_vm.provider = "password")
                                                )
                                              : ""
                                          },
                                        },
                                      },
                                      [_vm._v("Sign in ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-7",
                                    attrs: {
                                      justify: "center",
                                      "no-gutters": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mk-login-with-google",
                                        attrs: {
                                          elevation: "0",
                                          loading: _vm.signin_pending.google,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.login(
                                              $event,
                                              "basic",
                                              (_vm.provider = "google")
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-img", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            "max-width": "30px",
                                            src: require("../assets/social_icons/icon-google.png"),
                                          },
                                        }),
                                        _vm._v(" Continue With Google "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "baseline-content" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      "no-gutters": "",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "mb-0 mk-poppins--text" },
                                      [_vm._v("Don't have an account?")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "pt-1",
                                    attrs: {
                                      "no-gutters": "",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mk-login-page-create-btn",
                                        attrs: {
                                          depressed: "",
                                          plain: "",
                                          to: { path: "/auth/register" },
                                        },
                                      },
                                      [
                                        _vm._v("Create Account "),
                                        _c("v-icon", [
                                          _vm._v("arrow_right_alt "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }