<template>
  <v-container style="max-width:100%" fluid class="pa-0 m-background-gradient">
    <v-row no-gutters>
      <v-col class="hidden-sm-and-down">
        <v-img
          contain
          :src="require('@af/modules/auth/assets/Login_illustration.png')"
          position="top left"
          class="m-acc-illustration-img"
        />
        <div class="d-flex flex-column align-center justify-center h-100">
          <!--          <div>-->
          <!--            <v-row justify="center">-->
          <!--              <a :href="$store.state.siteUrl">-->
          <!--                <v-img-->
          <!--                  contain-->
          <!--                  src="../assets/logo.png"-->
          <!--                  class="m-auth__logo"-->
          <!--                  alt="Mouchak Logo"-->
          <!--                />-->
          <!--              </a>-->
          <!--            </v-row>-->
          <!--            <v-row justify="center">-->
          <!--              <h1>Here goes the content</h1>-->
          <!--            </v-row>-->
          <!--            <v-row justify="center">-->
          <!--              <h2>Here goes the content</h2>-->
          <!--            </v-row>-->
          <!--            <v-row justify="center">-->
          <!--              <h3>Here goes the content</h3>-->
          <!--            </v-row>-->
          <!--          </div>-->
        </div>
      </v-col>
      <v-col class="d-flex justify-center align-center h-100">
        <v-card class="m-login pa-4">
          <div class="d-flex flex-column">
            <v-spacer style="flex-grow: 0.6 !important"></v-spacer>
            <v-card-text class="pa-4">
              <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
                <v-row no-gutters>
                  <h3 class="text-md-center mb-2 m-text-black mk-poppins--text">
                    Sign in to Fluency First
                  </h3>
                </v-row>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container fluid class="pl-0">
                    <v-row no-gutters>
                      <v-text-field
                        label="Email"
                        autocomplete="username"
                        validate-on-blur
                        :prepend-icon="'email'"
                        v-model="form_data.email"
                        :rules="[emailValidation]"
                        autofocus
                      >
                      </v-text-field>
                    </v-row>
                    <v-row no-gutters justify="end">
                      <p
                        @click="$router.push('/auth/forgot-password')"
                        class="clickable primary--text mt-1 mb-0"
                        style="font-size: 0.81rem"
                      >
                        Forgot Password?
                      </p>
                    </v-row>
                    <v-row no-gutters>
                      <v-text-field
                        class="pt-0"
                        v-model="form_data.password"
                        :prepend-icon="'lock'"
                        :append-icon="
                          show_password ? 'visibility_off' : 'visibility'
                        "
                        :type="show_password ? 'text' : 'password'"
                        name="password"
                        autocomplete="current-password"
                        label="Password"
                        @keypress.native.enter="
                          login($event, 'basic', (provider = 'password'))
                        "
                        @click:append="show_password = !show_password"
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-row no-gutters justify="center">
                    <v-btn
                      class="mk-login-page-login-btn"
                      :loading="signin_pending.basic"
                      elevation="0"
                      @click="
                        valid
                          ? login($event, 'basic', (provider = 'password'))
                          : ''
                      "
                      color="primary"
                      >Sign in
                    </v-btn>
                  </v-row>

                  <v-row justify="center" class="mt-7" no-gutters>
                    <v-btn
                      class="mk-login-with-google"
                      elevation="0"
                      @click="login($event, 'basic', (provider = 'google'))"
                      :loading="signin_pending.google"
                    >
                      <v-img
                        class="mr-2"
                        max-width="30px"
                        :src="require('../assets/social_icons/icon-google.png')"
                      />
                      Continue With Google
                    </v-btn>
                    <!-- <v-btn
                      text
                      x-large
                      color="primary"
                      :ripple="false"
                      @click="login($event, 'basic', (provider = 'facebook'))"
                      :loading="signin_pending.facebook"
                      ><v-img
                        max-width="30px"
                        src="../assets/social_icons/icon-facebook.png"
                      />
                    </v-btn> -->
                  </v-row>
                </v-form>
                <div class="baseline-content">
                  <v-row no-gutters justify="center">
                    <p class="mb-0 mk-poppins--text">Don't have an account?</p>
                  </v-row>
                  <v-row no-gutters justify="center" class="pt-1">
                    <v-btn
                      class="mk-login-page-create-btn"
                      depressed
                      plain
                      :to="{ path: '/auth/register' }"
                      >Create Account
                      <v-icon>arrow_right_alt </v-icon>
                    </v-btn>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-spacer></v-spacer>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";
import signin from "@af/modules/auth/mixins/signin";

export default {
  name: "MLogin",
  props: {
    name: {
      type: String,
      default: "User"
    },
    activationNeeded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form_data: {
        password: "",
        email: ""
      },
      show_password: false,
      valid: true,
      activationDialog: this.activationNeeded,
      signin_pending: {
        basic: false,
        google: false,
        facebook: false
      }
    };
  },
  mixins: [FieldValidations, signin],
  computed: {
    SignupURL() {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );
      if (Object.keys(params).includes("redirectTo")) {
        return `/auth/register?redirectTo=${params.redirectTo}`;
      }
      return `/auth/register`;
    }
  },
  methods: {
    async login($event, type = "basic", provider) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (!this.valid && provider === "password") return;
      try {
        this.signin_pending[type] = true;
        let data = null;
        // eslint-disable-next-line prettier/prettier
        if (
          provider === "password" &&
          this.validate_password_data(this.$refs["form"])
        ) {
          data = this.form_data;
        } else {
          data = await this[`validate_${provider}_data`]();
        }
        if (data) {
          const payload = { data, type, provider };
          await this.$store.dispatch(this.$store.$types.AUTHENTICATE, payload);
          this.$emit("redirect");
        }
      } catch (error) {
        this.$root.$emit("alert", [
          "Alert",
          error.response.data.content
            ? JSON.stringify(error.response.data.content.error)
            : error.response.data.error.email
            ? "provide valid email"
            : "" || error.response.data.error.password
            ? "provide valid password"
            : "" || "Unexpected error message",
          true
        ]);
      } finally {
        this.signin_pending[type] = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.h-100 {
  height: 100vh !important;
}

.m-login {
  min-width: 500px;
  border-radius: 20px !important;
  min-height: 500px;

  @media (max-width: 600px) {
    min-width: 100vw;
    height: 100vh;
  }

  .v-card-title {
    padding-bottom: 5px;
  }

  .v-input--checkbox {
    .v-label {
      margin-bottom: 0;
    }
  }

  .theme--light.v-btn:hover::before {
    opacity: 0;
  }
}

.signup-success-dialog {
  width: auto !important;
}

.baseline-content {
  position: absolute;
  bottom: 45px;
  width: calc(100% - 70px);
}

.mk-login-page-login-btn {
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-weight: normal;
  text-transform: none;
  height: 40px !important;
  width: 100%;
  font-size: 16px !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    border: 0.5px solid #0099dc;
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }
}

.mk-login-page-create-btn {
  font-family: "Roboto", "Helvetica", "sans-serif";
  color: black !important;
  font-weight: normal;
  text-transform: none;
  height: 40px !important;
  width: 222px !important;
  font-size: 16px !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    border: 0.5px solid #0099dc;
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    border: 1px solid #0099dc;
    letter-spacing: 0.54px;
  }
}

.mk-login-with-google {
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-weight: normal;
  text-transform: none;
  height: 40px !important;
  width: 100%;
  font-size: 16px !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0.33px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    letter-spacing: 0.54px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    letter-spacing: 0.54px;
  }
}

.mk-login-with-google .v-btn__content,
.mk-login-page-create-btn .v-btn__content,
.mk-login-page-login-btn .v-btn__content {
  padding-top: 4px;
}
</style>
